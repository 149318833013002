import Papa from 'papaparse';
import { useContext, useEffect, useState, useRef } from "react";
import { Button, Row, Stack, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import BaseForm from "../../components/BaseForm";
import BaseContainer from "../../components/Container";
import Loader from "../../components/Loader";
import TabHeader from "../../components/TabHeader";
import { BaseContext, validateDate } from '../../helpers/common';
import { serverPost, serverFetch } from "../../helpers/server";
import { getTabItems } from "../../helpers/tabs";
import { CSVLink } from 'react-csv';
import { parseTime } from '../../helpers/common';

function ImportBooking() {
    const { getApiUrl, facilityLink, isLoggedIn} = useContext(BaseContext);
    const { t } = useTranslation('common');

    const [venues, setVenues] = useState({});
    const [venueNames, setVenueNames] = useState(new Set()); 
    const [rawCsvData, setRawCsvData] = useState([]);
    const [formattedData, setFormattedData] = useState([]);
    const [dataErrors, setDataErrors] = useState([]);
    const [improperFileError, setImproperFileError] = useState(false);
    const [importError, setImportError] = useState(false);
    const [formattingError, setFormattingError] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [uploaded, setUploaded] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [failedBookings, setFailedBookingsCsv] = useState([[
        "Date", 
        "Start Time", 
        "End Time", 
        "Venue Name", 
        "Group Name",
        "Event Type",
        "Event Name",
        "Note",
        "Error"
    ]]);
    const [successfulBookings, setSuccesfulBookingsCsv] = useState([[
        "Date", 
        "Start Time", 
        "End Time", 
        "Venue Name", 
        "Group Name",
        "Event Type",
        "Event Name",
        "Note",
        "Booking ID"
    ]]);

    const onImportBookingError = (response, errorMessage, index) => {
        setImportError(true);
        const key = Object.keys(errorMessage)[0]
        const value = Object.values(errorMessage)[0];

        if (key == "overlap") {
            setDataErrors(dataErrors => {
                const newDataErrors = [...dataErrors];
                newDataErrors[index] = {
                  ...newDataErrors[index],
                  "startTime": "There is overlap with this booking time." 
                };
                return newDataErrors;
              });
        } else if (key == "defaultRate") {
            setDataErrors(dataErrors => {
                const newDataErrors = [...dataErrors];
                newDataErrors[index] = {
                  ...newDataErrors[index],
                  "venueName": "Default rate for this venue is not set." 
                };
                return newDataErrors;
              });
         } else {
            setDataErrors(dataErrors => {
                const newDataErrors = [...dataErrors];
                newDataErrors[index] = {
                  ...newDataErrors[index],
                  [key]: value
                };
                return newDataErrors;
              });
        }
    }

    const updateStatus = (status, index) => {
        setFormattedData(prevFormattedData => {
          const newFormattedData = [...prevFormattedData];
          newFormattedData[index] = {
            ...newFormattedData[index],
            status: status
          };
          return newFormattedData;
        });
      }
    
    const formatBookingDataForCsv = (bookingData, bookingId = null, error = null) => {
        const formattedData = [
            bookingData["date"],
            bookingData["startTime"],
            bookingData["endTime"],
            bookingData["venueName"],
            bookingData["groupName"],
            bookingData["eventType"],
            bookingData["eventName"],
            bookingData["notes"]
        ]
        if (bookingId) {
            formattedData.push(bookingId)
        }
        if (error) {
            const key = Object.keys(error)[0]
            const value = Object.values(error)[0];
            if (key == "overlap") {
                formattedData.push("There is overlap with this booking time")
            } else if (key == "defaultRate") {
                formattedData.push("Default rate for this venue is not set.")
            } else {
                formattedData.push(value)
            }
        }
        return formattedData;
    }

    
    const importBooking = async (bookingData, index) => {
      updateStatus("UPLOADING");
      const { startTime, endTime, status, ...payload } = bookingData;
      try {
        var errorForBooking = null;
        const booking = await serverPost(getApiUrl('/bookings/import'), payload, {}, (response, errorMessage) => { errorForBooking = errorMessage; onImportBookingError(response, errorMessage, index)});

        if (booking) {
          updateStatus("SUCCESS", index);
          setSuccesfulBookingsCsv(successfulBookings => [...successfulBookings, formatBookingDataForCsv(bookingData, booking.id, null)]);
        } else {
          setImportError(true);
          updateStatus("ERROR", index);
          setFailedBookingsCsv(failedBookings => [...failedBookings, formatBookingDataForCsv(bookingData, null, errorForBooking)]);
        }              
      } catch (error) {
        setImportError(true);
        updateStatus("ERROR", index);
        setFailedBookingsCsv(failedBookings => [...failedBookings, formatBookingDataForCsv(bookingData, null, errorForBooking)]);
      }
    }

    const importBookings = async (data) => { 
        setUploading(true);
        for (let index = 0; index < data.length; index++) {
            await importBooking(data[index], index);
        }
        setUploading(false);
        setUploaded(true);
    }

    const csvTemplate = [[
        "Date", 
        "Start Time", 
        "End Time", 
        "Venue Name", 
        "Group Name",
        "Event Type",
        "Event Name",
        "Note",
    ]];

    const tableHeaders = [
      "Row",
      "Date", 
      "Start Time", 
      "End Time", 
      "Venue Name", 
      "Group Name",
      "Event Type",
      "Event Name",
      "Note",
      "Status"
    ];
    
    const formatData = (csvData) => {
        const csvColumns = csvData[0];
        const filteredCsvColumns = csvColumns.filter(column => column.trim() !== '');
        
        // There are not 8 columns or there is not any data (header column takes up one row so there needs to be at least 2 rows to have data)
        if (filteredCsvColumns.length !== 8 || csvData.length < 2) {
            setImproperFileError(true);
            return
        }

        const formattedData = [];
        const dataErrors = []

        csvData.forEach((row, rowIndex) => {

            // this is the headers row 
            if (rowIndex === 0) {
                return
            }

            const formattedRow = {};
            const dataErrorsRow = {};
            
            let date = "";
            let startTime = "";
            let endTime = "";

            let rowHasError = false;
            row.forEach((cell, colIndex) => {
                // Date is required
                if (colIndex === 0) {
                  formattedRow["date"] = cell;
                  if (!validateDate(cell)) {
                      dataErrorsRow["date"] = "Date must be in the format YYYY-MM-DD"
                      setFormattingError(true);
                      rowHasError = true;
                  } else {
                      dataErrorsRow["date"] = null;
                      date = cell;
                  }
                }
                
                // Start time is required
                if (colIndex === 1) {
                  const parsedStartTime = parseTime(cell);
                  if (parsedStartTime == null) {
                      dataErrorsRow["startTime"] = "Start time must be in the format HH:MM:SS"
                      formattedRow["startTime"] = cell;
                      setFormattingError(true);
                      rowHasError = true;
                  } else {
                      dataErrorsRow["startTime"] = null;
                      formattedRow["startTime"] = parsedStartTime;
                      startTime = parsedStartTime;
                  }
                }

                // End time is required
                if (colIndex === 2) {
                  const parsedEndTime = parseTime(cell);
                  if (!parsedEndTime) {
                      dataErrorsRow["endTime"] = "End time must be in the format HH:MM:SS"
                      formattedRow["endTime"] = cell;
                      setFormattingError(true);
                      rowHasError = true;
                  } else {
                      dataErrorsRow["endTime"] = null;
                      formattedRow["endTime"] = parsedEndTime;
                      endTime = parsedEndTime;
                  }
                }

                // Venue name is required
                if (colIndex === 3) {
                    if (cell.trim() === '') {
                        dataErrorsRow["venueName"] = "Venue name is empty"
                        formattedRow["venueName"] = null;
                        setFormattingError(true);
                        rowHasError = true;
                    } else if (!venueNames.has(cell)) {
                        dataErrorsRow["venueName"] = "Venue name is not in the facility"
                        formattedRow["venueName"] = cell; 
                        setFormattingError(true);
                        rowHasError = true;
                    }
                    else {
                        dataErrorsRow["venueName"] = null;
                        formattedRow["venueName"] = cell;
                    }
                }
                
                // Group name is required
                if (colIndex === 4) {
                    if (cell.trim() === '') {
                        dataErrorsRow["groupName"] = "Group name is empty"
                        formattedRow["groupName"] = null;
                        setFormattingError(true);
                        rowHasError = true;
                    } else {
                        dataErrorsRow["groupName"] = null;
                        formattedRow["groupName"] = cell;
                    }
                }

                // Event type is required
                if (colIndex === 5) {
                  if (cell.trim() === '') {
                        dataErrorsRow["eventType"] = "Event type is empty"
                        formattedRow["eventType"] = null;
                        setFormattingError(true);
                        rowHasError = true;
                    } else {
                        dataErrorsRow["eventType"] = null;
                        formattedRow["eventType"] = cell;
                    }
                }

                // Event name is not required
                if (colIndex === 6) {
                  dataErrorsRow["eventName"] = null;
                  if (cell.trim() === '') {
                    formattedRow["eventName"] = null
                  } else {
                    formattedRow["eventName"] = cell
                  }
                }
                
                // Note is not required
                if (colIndex === 7) {
                  dataErrorsRow["notes"] = null;
                  if (cell.trim() === '') {
                    formattedRow["notes"] = null
                  } else {
                    formattedRow["notes"] = cell
                  }
                }
            })
            
            if (rowHasError) {
              formattedRow["status"] = "ERROR";
            } else {
              formattedRow["status"] = "PENDING";
            }
            
            formattedRow["startTimeLocal"] = `${date}T${startTime}`;
            formattedRow["endTimeLocal"] = `${date}T${endTime}`;
            formattedData.push(formattedRow);
            dataErrors.push(dataErrorsRow)
        });
        setFormattedData(formattedData);
        setDataErrors(dataErrors);
    }

    const onFieldChange = (name, file) => {
        if (name === "csv" && file != null) {
            setImproperFileError(false);
            setUploading(false);
            setUploaded(false);
            setUploadSuccess(false);
            setFormattingError(false);
            setImportError(false);
            setRawCsvData([]);
            setFormattedData([]);
            setDataErrors([]);
            
            Papa.parse(file, {
                skipEmptyLines: true,
                complete: (results) => {
                    setRawCsvData(results.data);
                }
            })

        }
    }

    useEffect(() => {
        serverFetch(getApiUrl('/venues')).then((res) => {
            const venueNamesSet = new Set(res.map(venue => venue.name));
            setVenueNames(venueNamesSet);
        })
    }, [isLoggedIn]);

    useEffect(() => {
        if (rawCsvData.length > 0) {
            setLoading(true);
            formatData(rawCsvData);
            setLoading(false);
        }
    }, [rawCsvData])

    useEffect(() => {    
        if (importError === false && uploaded === true) {
            setUploadSuccess(true);
        }
    }, [importError, uploaded]);

    return (
        <BaseContainer>
            <TabHeader items={getTabItems(t, facilityLink, "import-booking")} />
            <BaseForm initialFormFields={venues} onFieldChange={onFieldChange} onSubmit={importBookings}>
                <div className="content-box">
                    <div className="content-header">
                        <span className="content-title">{t('venue_settings.csv_upload')}</span>
                    </div>

                    <div className="content-body">
                        <span>{t("booking.import.template")}</span>
                        <div style={{marginTop: '10px', marginBottom: '10px'}}>
                            <CSVLink data={csvTemplate} filename={"CSV-Import-Bookings-Template.csv"} target="_blank">
                                <a style={{textDecoration: 'underline'}}>{t("booking.import.download_template")}</a>
                            </CSVLink>
                        </div>
                        
                        <ul>
                            <li>{t("booking.import.date")}</li>
                            <li>{t("booking.import.start_time")}</li>
                            <li>{t("booking.import.end_time")}</li>
                            <li>{t("booking.import.venue_name")}</li>
                            <li>{t("booking.import.group_name")}</li>
                            <li>{t("booking.import.event_type")}</li>
                            <li>{t("booking.import.event_name")}</li>
                            <li>{t("booking.import.notes")}</li>
                        </ul>

                        <Row>
                            <BaseForm.Input colSpan="6" type="file" name="csv" label="Import CSV" accept=".csv" fileType="invoice_logo" />
                        </Row>

                        <br/>
                        
                        <div>    
                            <Loader loading={uploading}>
                                <div>
                                    <Button disabled={formattedData.length === 0 || uploaded || formattingError || improperFileError} onClick={() => importBookings(formattedData)}>{t("booking.import.upload")}</Button>
                                    {(improperFileError || formattingError) && (
                                        <span style={{color: 'red', marginLeft: '15px'}}>{t("booking.import.fix_errors_before_uploading")}</span>
                                    )}
                                </div>
                            </Loader>

                            {uploadSuccess && (
                                <div>
                                    <br/>
                                    <span style={{color: 'green'}}>{t("booking.import.upload_success")}</span>
                                </div>
                            )}  

                            {(uploaded && importError) && (
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                    <br/>
                                    <span style={{color: 'red'}}>{t("booking.import.upload_error")} </span>
                                    
                                    <br/>
                                    <div>
                                    <CSVLink data={failedBookings} filename={"CSV-Failed-Import-Bookings.csv"} target="_blank">
                                        <a style={{textDecoration: 'underline', paddingRight: '25px'}}>{t("booking.import.download_failed_bookings")}</a>
                                    </CSVLink>

                                    <CSVLink data={successfulBookings} filename={"CSV-Successful-Import-Bookings.csv"} target="_blank">
                                        <a style={{textDecoration: 'underline'}}>{t("booking.import.download_successful_bookings")}</a>
                                    </CSVLink>

                                    </div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            
                <div className="content-box">
                    <div className="content-header">
                        <span className="content-title">CSV Data</span>
                    </div>
                    <div className="content-body">
                        <Loader loading={loading}>
                            { (!improperFileError && formattedData.length === 0) && (
                                <span>{t('booking.import.csv_loaded_here')}</span>
                            )}
                            { improperFileError && (
                                <span>{t('booking.import.file_incorrect_format')}</span>
                            )}
                            { (!improperFileError && formattedData && formattedData.length > 0) && (
                                    <Table>
                                        <thead>
                                            <tr>
                                                {tableHeaders.map(header => (
                                                    <th key={header}>{header}</th>
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {formattedData.map((row, rowIndex) => (
                                                <tr key={rowIndex}>
                                                        <td>
                                                          <span>{rowIndex+1}</span>
                                                        </td>
                                                    {Object.entries(row).map(([key, cell], colIndex) => (
                                                      (key !== 'startTimeLocal' && key !== 'endTimeLocal') && (
                                                          <td>
                                                              <div style={{display: 'flex', flexDirection: 'column'}}>
                                                                  {(key !== 'status') && (
                                                                      <span> {(cell) && cell} </span>
                                                                  )}
                                                                  
                                                                  {(key === "status") && (
                                                                      <span
                                                                          className={
                                                                            cell === "ERROR" ? "label label-danger" :
                                                                            cell === "PENDING" ? "label label" :
                                                                            cell === "UPLOADING" ? "label label-warning" :
                                                                            cell === "SUCCESS" ? "label label-success" : "label label"
                                                                          }
                                                                          style={{textAlign: 'center'}}
                                                                      >
                                                                          {cell}
                                                                      </span>
                                                                  )}

                                                                  {(dataErrors[rowIndex][key]) && (
                                                                      <span style={{color: 'red'}}>{dataErrors[rowIndex][key]}</span>
                                                                  )}
                                                              </div>
                                                          </td>
                                                    )))}
                                                </tr>
                                            ))}
                                        </tbody>
                                </Table>                                
                            )}
                        </Loader>
                    </div>
                </div>
            </BaseForm>
        </BaseContainer>
    )
}

export default ImportBooking